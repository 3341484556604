import { useState } from "react"

import {
  usePostHomegroupAddAddressConfirmed,
  usePostHomegroupAddAddressInitiated,
} from "src/data/analytics/queries/homegroupAnalyticsQueries"
import {
  useFetchHomeGroup,
  usePatchHomeGroup,
} from "src/data/homeGroups/queries/homeGroupQueries"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { ILocation, THomeAddress } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

import {
  HOME_GROUP_ADDRESS_FORM_ID,
  StepHomeGroupAddress,
} from "./StepHomeGroupAddress"
import { StepHomeGroupMap } from "./StepHomeGroupMap"

export function HomeGroupAddressWizard({
  homeGroupId,
  address,
  location,
}: {
  homeGroupId: string
  address?: THomeAddress
  location?: ILocation
}) {
  const { orgId } = useOrganization()
  const [currentStep, setCurrentStep] = useState(0)
  const [homeGroupAddress, setHomeGroupAddress] = useState<
    THomeGroup["address"] | undefined
  >(address)
  const [homeGroupLocation, setHomeGroupLocation] = useState<
    ILocation | undefined
  >(location)
  const { goBack, navigate } = useRouter()

  const fetchHomeGroup = useFetchHomeGroup({ orgId, homeGroupId })
  const patchHomeGroup = usePatchHomeGroup()

  const { t, langKeys } = useTranslate()

  const postHomegroupAddAddressInitiated = usePostHomegroupAddAddressInitiated()
  const postHomegroupAddAddressConfirmed = usePostHomegroupAddAddressConfirmed()

  useEffectOnce(() => {
    postHomegroupAddAddressInitiated.mutate()
  })

  function handleHomeAddressStep(
    address: THomeGroup["address"],
    location: ILocation
  ) {
    if (!address?.street_name1) {
      return
    }
    // Spread address here since otherwise it might overwrite things not handled by the form, like notes
    setHomeGroupAddress((prev) => ({ ...prev, ...address }))
    setHomeGroupLocation(location)
    setCurrentStep((currStep) => currStep + 1)
  }

  function handleSubmit() {
    if (fetchHomeGroup.isError || fetchHomeGroup.isLoading) {
      return alert("Cannot save home group address")
    }
    postHomegroupAddAddressConfirmed.mutate()

    patchHomeGroup.mutate(
      {
        homeGroupId,
        orgId,
        body: {
          name: fetchHomeGroup.data.name, // This should not be required for a PATCH but the BE is currently typed incorrectly
          address: homeGroupAddress,
          location: homeGroupLocation,
          type: "area",
        },
      },
      {
        onSuccess(data, variables) {
          navigate({
            pathname: Routes.HomeGroupDetails.path(variables.homeGroupId),
            hash: Routes.HomeGroupDetails.tabs[2],
          })
        },
      }
    )
  }

  const stepper: IWizardStep[] = [
    {
      component: (
        <StepHomeGroupAddress
          type="area"
          onSuccess={handleHomeAddressStep}
          address={homeGroupAddress}
          location={homeGroupLocation}
        />
      ),
      nextButtonProps: {
        form: HOME_GROUP_ADDRESS_FORM_ID,
      },
      nextButtonLabel: t(langKeys.continue),
      onBack: () => goBack({ defaultPath: Routes.Home.location(homeGroupId) }),
      hideProgress: true,
    },
    {
      component: (
        <StepHomeGroupMap
          onChangeLocation={setHomeGroupLocation}
          location={homeGroupLocation}
          error={patchHomeGroup.error}
        />
      ),
      nextButtonLabel: t(langKeys.save),
      nextButtonProps: {
        loading: patchHomeGroup.isLoading,
      },
      onNext: () => handleSubmit(),
      hideProgress: true,
    },
  ]

  return (
    <FullscreenWizard
      title={t(langKeys.homegroup_location_address_edit)}
      steps={stepper}
      currentStep={currentStep}
      onClose={() =>
        goBack({ defaultPath: Routes.HomeGroupDetails.location(homeGroupId) })
      }
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
