import styled from "styled-components"

import { LayoutMain } from "src/components/AppLayout/LayoutMain"
import { EmptyState } from "src/components/EmptyState"
import { HomeGroupAddressWizard } from "src/components/HomeGroups/HomeGroupAddressWizard/HomeGroupAddressWizard"
import { useFetchHomeGroup } from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import Svg404 from "src/ui/icons/404.svg"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"

export function HomeGroupAddressEdit({ homeGroupId }: { homeGroupId: string }) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()

  const fetchHomeGroup = useFetchHomeGroup({ orgId, homeGroupId })
  const address = fetchHomeGroup.data?.address
  const location = fetchHomeGroup.data?.location

  if (fetchHomeGroup.isError) {
    return (
      <LayoutMain>
        <EmptyState
          title={t(langKeys.failed_general_error_title)}
          body={t(langKeys.failed_general_error_body)}
          icon={<Svg404 />}
        />
      </LayoutMain>
    )
  }
  if (fetchHomeGroup.isLoading) {
    return (
      <Fetching>
        <MCircularProgress size={35} />
      </Fetching>
    )
  }

  return (
    <HomeGroupAddressWizard
      homeGroupId={homeGroupId}
      address={address}
      location={location}
    />
  )
}

const Fetching = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.XL};
  justify-content: center;
`
